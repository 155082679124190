import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { TruncatePipe } from "src/app/pipes/truncate.pipe";
import { CustomMaxValidatorDirective } from "src/app/directive/custom-max-validator.directive";
import { CustomMinValidatorDirective } from "src/app/directive/custom-min-validator.directive";

@NgModule({
  declarations: [ TruncatePipe,CustomMaxValidatorDirective,CustomMinValidatorDirective],
  imports: [CommonModule, RouterModule, FormsModule, ReactiveFormsModule],
  exports: [TruncatePipe,CustomMaxValidatorDirective,CustomMinValidatorDirective],
})
export class SharedModule {}
