import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { guardRedirect } from '../library/guard-redirect';
import { authState, AuthState } from '../store/app.states';
declare var $: any

@Injectable({
  providedIn: 'root'
})
export class IsAuthenticatedGuard implements CanActivate {

  constructor(
    public router: Router,
    private store: Store<AuthState>,
) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    // return true;
    // let user:any = JSON.parse(sessionStorage.getItem('user'))
    // if(user){
    //   if (user.signInUserSession.accessToken.jwtToken) {
    //     return of(true);
    //   }else{
    //     this.router.navigate(["/"]);
    //     return of(false);
    //   }
    // }else{
    //   return of(false);
    // }
      return this.store.select(authState).pipe(
          switchMap((data: any) => {
            
              if (!data.isAuthenticated) {
                  guardRedirect(data, this.router);
                  return of(false)
              }
              return of(true)
          })
      )
  }
  
}
