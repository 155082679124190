import { NgModule } from "@angular/core";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { IsAuthenticatedGuard } from "src/app/guards/is-authenticated.guard";
import { TokenInterceptor } from "src/app/services/interceptor/token-interceptor/token-interceptor.interceptor";
import { ErrorInterceptor } from "src/app/services/interceptor/error-interceptor/error-interceptor.interceptor";

@NgModule({
  providers: [
    IsAuthenticatedGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true,
    },
  ],
})
export class AccountsModule {}
