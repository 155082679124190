import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AuthActionTypes } from '../actions/auth.actions';
declare var $: any;

@Injectable()
export class AuthEffects {

  constructor(private actions: Actions,
    private router: Router,
  ) { }

  @Effect({ dispatch: false })
  LogInSuccess: Observable<any> = this.actions.pipe(
    ofType(AuthActionTypes.LOGIN_SUCCESS),
    tap((data) => {
      
      if (data.payload.serverResponse) {
        // this.router.navigate(['/', 'admin', 'dashboard']);
        this.router.navigate(['/','dashboard']);
      }
    })
  );
  @Effect({ dispatch: false })
  LoggedIn: Observable<any> = this.actions.pipe(
    ofType(AuthActionTypes.LOGGED_IN),
    tap((data) => {
      
      if (!data.payload.serverResponse) {
        this.router.navigate(['/'])
      }
    })
  );

  @Effect({ dispatch: false })
  LogInFailure: Observable<any> = this.actions.pipe(
    ofType(AuthActionTypes.LOGIN_FAILURE)
  );

  @Effect({ dispatch: false })
  public LogOut: Observable<any> = this.actions.pipe(
    ofType(AuthActionTypes.LOGOUT),
    tap((user) => {
      
      this.router.navigate(['/','auth','login'])
    })
  );
  @Effect({ dispatch: false })
  CustomerLoggedIn: Observable<any> = this.actions.pipe(
    ofType(AuthActionTypes.CUSTOMER_LOGGED_IN),
    tap((data) => {
      
      if (data.payload.serverResponse) {
        // this.router.navigate(['/', 'admin', 'dashboard']);
        this.router.navigate(['/','dashboard']);
      }
    })
  );
}