import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from '@angular/router';
import { AuthService } from './auth.service';

import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { guardRedirect } from '../../../library/guard-redirect';
import { authState, AuthState } from '../../../store/app.states';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
  constructor(private authService: AuthService,public router: Router,
    private store: Store<AuthState>) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    
    // const currentUser = this.authService.currentUserValue;
    // if (currentUser) {
    //   // logged in so return true
    //   return true;
    // }

    // // not logged in so redirect to login page with the return url
    // this.authService.logout();
    return this.store.select(authState).pipe(
      switchMap((data: any) => {
        
          if (!data.isAuthenticated) {
              guardRedirect(data, this.router);
              return of(false)
          }
          return of(true)
      })
  )
  }
}
