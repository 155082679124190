import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';

import { Auth } from 'aws-amplify';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Store } from "@ngrx/store";
import { LogOut } from "src/app/store/actions/auth.actions";
@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

  constructor(private router: Router, private toastr: ToastrService , private store:Store) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(catchError(error => {
      if (error.status === 401 || error.status === 403) {
        // auto logout if 401 response returned from api
        this.signOut();
        this.toastr.error("Please login again to continue.");
        // location.reload(true);
      } 
      // else if (error.status === 403) {
      //   this.signOut()
      //   this.toastr.error("Please login again to continue.");
      //   // this.toastr.error(error.error.message ? error.error.message : "Something went wrong..!")
      // }
      // const error = err.error.message || err.statusText;
      return throwError(error);
    }))
    // return next.handle(request);
  }

  async signOut() {
    try {
      await Auth.signOut();
      sessionStorage.clear();
      localStorage.clear();
      this.store.dispatch(new LogOut);
      this.router.navigate(['/auth/login'])
    } catch (error) {
      
    }
  }


}
