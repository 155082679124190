import { Action } from '@ngrx/store';


export enum AuthActionTypes {
  LOGIN_SUCCESS = '[Auth] Login Success',
  LOGGED_IN = '[Auth] LoggedIn',
  LOGIN_FAILURE = '[Auth] Login Failure',
  LOGOUT = '[Auth] Logout',
  CUSTOMER_LOGGED_IN = '[Auth] Customer LoggedIn',
}

export class LogInSuccess implements Action {
  readonly type = AuthActionTypes.LOGIN_SUCCESS;
  constructor(public payload: any) { }
}
export class LoggedIn implements Action {
  readonly type = AuthActionTypes.LOGGED_IN;
  constructor(public payload: any) { }
}
export class LogInFailure implements Action {
  readonly type = AuthActionTypes.LOGIN_FAILURE;
  constructor(public payload: any) { }
}
export class LogOut implements Action {
  readonly type = AuthActionTypes.LOGOUT;
}
export class CustomerLoggedIn implements Action {
  readonly type = AuthActionTypes.CUSTOMER_LOGGED_IN;
  constructor(public payload: any) { }
}


export type All =
  | LogInSuccess
  | LoggedIn
  | LogInFailure
  | LogOut
  | CustomerLoggedIn;