import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable, Compiler } from "@angular/core";
import { Store } from "@ngrx/store";
import { Auth } from "aws-amplify";
import { BehaviorSubject, Observable, of } from "rxjs";
import { switchMap } from "rxjs/operators";
import { CustomerLoggedIn, LoggedIn, LogOut } from "src/app/store/actions/auth.actions";
import { authState, AuthState } from "src/app/store/app.states";
import { environment } from "src/environments/environment";
// import {aws4} from 'aws4'
// var aws4 = require("aws4");
declare var $: any;
const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',

  })
}
@Injectable({
  providedIn: "root",
})
export class SharedServiceService {
  baseUrl = environment.baseUrl;
  testdcp = "/testdcp";
  authUserData;
  private messageSource = new BehaviorSubject<string>("dashboard");
  selectedSidebarOption = this.messageSource.asObservable();
  sharedService: any;
  permissions: any;
  toastr: any;

  constructor(private http: HttpClient, private store: Store<AuthState>, private _compiler: Compiler) { }

  getParams(args = {}) {
    let params = new HttpParams();
    Object.keys(args).forEach((element) => {
      if (args[element]) {
        params = params.append(element, args[element]);
      }
    });
    return params;
  }

  changeSelectedSidebarOption(message: string) {
    this.messageSource.next(message);
  }

  getUserRequestsList(paramData = {}): Observable<any> {
    let params = this.getParams(paramData);
    const url = this.baseUrl + `/user/v1/GetRequestList`;
    return this.http.get(url, { params });
  }

  getUsersList(parentUser, paramData = {}): Observable<any> {
    const headers = new HttpHeaders().set("parentUser", parentUser);
    let params = this.getParams(paramData);
    const url = this.baseUrl + `/user/v1/GetUserList`;
    return this.http.get(url, { params, headers: headers });
  }

  deleteUserList(users): Observable<any> {
    // const headers = new HttpHeaders().set(
    //   // "usernames",users
    // );
    // 
    const url = this.baseUrl + `/user/v1/DeleteUser`;
    return this.http.request("delete", url, { body: users });
  }

  getUserDetails(userId): Observable<any> {
    // const headers = new HttpHeaders().set("userId", userId);
    const url = this.baseUrl + `/user/v1/GetUserDetails/${userId}`;
    return this.http.get(url, {});
  }

  approveUserRequest(postData, email): Observable<any> {
    // const headers = new HttpHeaders().set("createdBy", email);
    const url = this.baseUrl + `/user/v1/ApproveUser/` + email;
    return this.http.post(url, postData);
  }

  declineUserRequest(postData, email): Observable<any> {
    // const headers = new HttpHeaders().set("createdBy", email);
    const url = this.baseUrl + `/user/v1/DeclineUser/` + email;
    return this.http.put(url, postData);
  }

  holdUserRequest(postData, email): Observable<any> {
    // const headers = new HttpHeaders().set("createdBy", email);
    const url = this.baseUrl + `/user/v1/PutOnHoldUser/` + email;
    return this.http.put(url, postData);
  }

  getUserData() {
    return this.store.select(authState).pipe(
      switchMap((data: any) => {
        if (!data.isAuthenticated) {
          return of(false);
        }
        return of(data);
      })
    );
  }

  getToken() {
    return this.store.select(authState).pipe(
      switchMap((data: any) => {
        if (!data.isAuthenticated) {
          return of(false);
        }
        return of(data.user.token);
      })
    );
  }
  getRefreshToken() {
    return this.store.select(authState).pipe(
      switchMap((data: any) => {
        if (!data.isAuthenticated) {
          return of(false);
        }
        return of(data.user.refreshToken);
      })
    );
  }

  createProject(postData): Observable<any> {
    // const headers = new HttpHeaders().set("createdBy", postData["createdBy"]);
    const url = this.baseUrl + `/projects/v1/CreateProject/`;
    return this.http.post(url, postData);
  }
  getDMPUsers(paramData = {}): Observable<any> {
    let params = this.getParams(paramData);
    const url = this.baseUrl + `/user/v1/GetDMPUsers`;
    return this.http.get(url, { params });
  }
  deleteProject(id): Observable<any> {
    // const headers = new HttpHeaders().set("projectId", id);
    const url = this.baseUrl + `/projects/v1/DeleteProject/${id}`;
    return this.http.delete(url);
  }

  updateProject(patchData, projectId): Observable<any> {
    // const headers = new HttpHeaders().set("projectId", projectId);
    const url = this.baseUrl + `/projects/v1/UpdateProject/${projectId}`;
    return this.http.put(url, patchData);
  }

  getProject(projectId): Observable<any> {
    // const headers = new HttpHeaders().set("projectId", id);
    const url = this.baseUrl + `/projects/v1/GetProjectDetails/${projectId}`;
    return this.http.get(url);
  }

  getNormalDeviceProfilesList(paramData = {}): Observable<any> {
    // const headers = new HttpHeaders().set("createdBy", paramData["createdBy"]);
    let params = this.getParams(paramData);
    const url = this.baseUrl + `/deviceprofiles/v1/GetDeviceProfilesList`;
    return this.http.get(url, { params });
  }

  getDeviceProfilesList(paramData = {}): Observable<any> {
    // const headers = new HttpHeaders().set("createdBy", paramData["createdBy"]);
    let params = this.getParams(paramData);
    const url = this.baseUrl + `/deviceprofiles/v1/GetDeviceProfiles`;
    return this.http.get(url, { params });
  }

  createDeviceProfile(parentId, versionId, postData): Observable<any> {
    const headers = new HttpHeaders().set("deviceProfileVersionId", versionId);
    const url = this.baseUrl + `/deviceprofiles/v1/CreateNewDeviceProfile/`;
    return this.http.post(url, postData, { headers: headers });
  }

  deleteDeviceProfile(deviceProfileId, versionId): Observable<any> {
    const headers = new HttpHeaders().set("versionId", versionId);
    const url =
      this.baseUrl +
      `/deviceprofiles/v1/DeleteDeviceProfile/${deviceProfileId}`;
    return this.http.request("delete", url, {
      headers: headers,
    });
  }

  updateDeviceProfile(deviceProfileId, patchData): Observable<any> {
    // const headers = new HttpHeaders().set(" deviceProfileId", deviceProfileId);
    const url =
      this.baseUrl +
      `/deviceprofiles/v1/UpdateDeviceProfile/${deviceProfileId}`;
    // return this.http.put(url, patchData, { headers: headers });
    return this.http.put(url, patchData);
  }

  updateDeviceProfileAsNew(
    versionId,
    deviceProfileId,
    patchData
  ): Observable<any> {
    const headers = new HttpHeaders();
    headers.set("versionId", versionId);
    const url =
      this.baseUrl +
      `/deviceprofiles/v1/CreateNewDeviceProfileVersion/${deviceProfileId}`;
    // return this.http.post(url, patchData, { headers: headers });
    return this.http.post(url, patchData, { headers: headers });
  }

  getDeviceProfile(profileId, versionId): Observable<any> {
    const headers = new HttpHeaders().set("versionId", versionId);
    const url =
      this.baseUrl +
      // `/deviceprofiles/v1/GetDeviceProfileDetails/${profileId}?versionId=${versionId}`;
      `/deviceprofiles/v1/GetDeviceProfileDetails/${profileId}`;
    return this.http.get(url, { headers: headers });
  }

  sendRequestForDeviceProfileApproval(
    profileId,
    versionId,
    patchData
  ): Observable<any> {
    const headers = new HttpHeaders().set("versionId", versionId);

    const url =
      this.baseUrl + `/deviceprofiles/v1/SubmitForApproval/${profileId}`;
    return this.http.put(url, patchData, { headers: headers });
  }

  approveDeviceProfileRequest(profileID, patchData): Observable<any> {
    // const headers = new HttpHeaders().set("versionId", patchData["versionId"]);
    const url =
      this.baseUrl + `/user/v1/ApproveDeviceProfileRequest/${profileID}`;
    return this.http.put(url, patchData);
  }

  declineDeviceProfileRequest(profileID, patchData): Observable<any> {
    // const headers = new HttpHeaders().set("versionId", patchData["versionId"]);
    const url =
      this.baseUrl + `/user/v1/DeclineDeviceProfileRequest/${profileID}`;
    return this.http.put(url, patchData);
  }

  syncDevices(projectId): Observable<any> {
    const headers = new HttpHeaders().set("projectId", projectId);
    // const url = this.baseUrl + `/devices/v1/SyncDevices?projectId=${id}`;
    const url = this.baseUrl + `/devices/v1/SyncDevices`;
    return this.http.get(url, { headers: headers });
  }

  getAllDevices(projectId, paramData = {}): Observable<any> {
    const headers = new HttpHeaders().set("projectId", projectId);
    let params = this.getParams(paramData);
    const url = this.baseUrl + `/devices/v1/GetAllDevices`;
    return this.http.get(url, { params, headers: headers });
  }

  getDeviceDetails(deviceId): Observable<any> {
    // const headers = new HttpHeaders().set("deviceId", deviceId);
    const url = this.baseUrl + `/devices/v1/GetDeviceDetails/${deviceId}`;
    return this.http.get(url);
  }

  getApprovedDeviceProfiles(projectId, paramData = {}): Observable<any> {
    let params = this.getParams(paramData);
    const headers = new HttpHeaders().set("projectId", projectId);
    const url = this.baseUrl + `/deviceprofiles/v1/GetApprovedDeviceProfile`;
    return this.http.get(url, { params, headers: headers });
  }

  linkDeviceProfile(deviceProfileId, projectId, patchData): Observable<any> {
    const headers = new HttpHeaders().set("projectId", projectId);
    const url =
      this.baseUrl + `/devices/v1/LinkDeviceProfile/${deviceProfileId}`;
    return this.http.put(url, patchData, { headers: headers });
  }

  delinkDeviceProfile(deviceProfileId, projectId, patchData): Observable<any> {
    const headers = new HttpHeaders().set("projectId", projectId);
    const url =
      this.baseUrl +
      `/devices/v1/DeLinkDeviceFromDeviceProfile/${deviceProfileId}`;
    return this.http.put(url, patchData, { headers: headers });
  }

  getAssets(projectId, paramData = {}): Observable<any> {
    const headers = new HttpHeaders().set(
      // "projectId", paramData['projectId']
      "projectId",
      projectId
    );
    let params = this.getParams(paramData);
    const url = this.baseUrl + `/assets/v1/ListAssets`;
    return this.http.get(url, { params, headers: headers });
  }

  getAssetDetails(name, projectId): Observable<any> {
    const headers = new HttpHeaders().set(
      // "projectId", paramData['projectId']
      "projectId",
      projectId
    );
    // let params = this.getParams(paramData);
    const url = this.baseUrl + `/assets/v1/GetAssetDetail/${name}`;
    return this.http.get(url, { headers: headers });
  }

  createAsset(postData, projectId): Observable<any> {
    const headers = new HttpHeaders().set(
      // "projectId", postData['projectId'],
      "projectId",
      projectId
    );
    const url = this.baseUrl + `/assets/v1/CreateNewAsset`;
    return this.http.post(url, postData, { headers: headers });
  }

  updateAsset(name, patchData): Observable<any> {
    const headers = new HttpHeaders().set("assetName", name);
    const url = this.baseUrl + `/assets/v1/UpdateAssets/${name}`;
    return this.http.put(url, patchData);
  }

  deleteAsset(id): Observable<any> {
    const headers = new HttpHeaders().set("names", id);
    const url = this.baseUrl + `/assets/v1/DeleteAsset/${id}`;
    return this.http.delete(url, { headers: headers });
  }

  deleteAssets(options, projectId): Observable<any> {
    const headers = new HttpHeaders().set(
      // "names", options['names'],
      "projectId",
      projectId
    );
    // 
    const url = this.baseUrl + `/assets/v1/DeleteAsset`;
    return this.http.request("delete", url, {
      body: options,
      headers: headers,
    });
  }

  linkAsset(deviceId, projectId, patchData): Observable<any> {
    const headers = new HttpHeaders().set("projectId", projectId);
    const url = this.baseUrl + `/devices/v1/AssignAssetToDevice/${deviceId}`;
    return this.http.put(url, patchData, { headers: headers });
  }

  delinkAsset(deviceId, projectId, patchData): Observable<any> {
    const headers = new HttpHeaders().set("projectId", projectId);
    const url =
      this.baseUrl + `/devices/v1/WithdrawAssetFromDevice/${deviceId}`;
    return this.http.put(url, patchData, { headers: headers });
  }

  getTags(projectId, paramData = {}): Observable<any> {
    const headers = new HttpHeaders().set("projectId", projectId);
    let params = this.getParams(paramData);
    const url = this.baseUrl + `/assets/v1/ListTags`;
    return this.http.get(url, { params, headers: headers });
  }

  createTag(postData, projectId): Observable<any> {
    const headers = new HttpHeaders().set(
      // "tagName", postData['tagName']
      "projectId",
      projectId
    );
    const url = this.baseUrl + `/assets/v1/CreateAssetTag`;
    return this.http.post(url, postData, { headers: headers });
  }

  updateTag(tagId, patchData): Observable<any> {
    const headers = new HttpHeaders().set("tagId", tagId);
    const url = this.baseUrl + `/assets/v1/UpdateAssetTag/${tagId}`;
    return this.http.put(url, patchData, { headers: headers });
  }

  associateTag(assetName, postData, projectId): Observable<any> {
    const headers = new HttpHeaders().set(
      // "projectId", postData['projectId']
      "projectId",
      projectId
    );
    const url = this.baseUrl + `/assets/v1/AssignTagToAsset/${assetName}`;
    return this.http.post(url, postData, { headers: headers });
  }

  associateTagAll(postData, projectId): Observable<any> {
    const headers = new HttpHeaders().set(
      // "projectId", postData['projectId']
      "projectId",
      projectId
    );
    const url = this.baseUrl + `/assets/v1/AssignTagToAssetHierarchy`;
    return this.http.post(url, postData, { headers: headers });
  }

  deleteTags(options, projectId): Observable<any> {
    // 
    const headers = new HttpHeaders().set(
      // "tagId", options['tagIds']
      "projectId",
      projectId
    );
    const url = this.baseUrl + `/assets/v1/DeleteAssetTags`;
    return this.http.request("delete", url, {
      body: options,
      headers: headers,
    });
  }

  removeTags(name, options): Observable<any> {
    // 
    const headers = new HttpHeaders().set("assetName", name);
    const url = this.baseUrl + `/assets/v1/WithdrawTagFromAsset/${name}`;
    return this.http.request("delete", url, {
      body: options,
      headers: headers,
    });
  }

  downloadTemplate(assetName, projectId, paramData = {}): Observable<any> {
    const headers = new HttpHeaders().set("projectId", projectId);
    let params = this.getParams(paramData);
    const url = this.baseUrl + `/assets/v1/DownloadTemplate/${assetName}`;
    return this.http.get(url, { params, headers: headers });
  }

  getProcessAssetTagAssociationExcel(postData): Observable<any> {
    const url = this.baseUrl + `/assets/v1/ProcessAssetTagAssociationExcel`;
    return this.http.post(url, postData);
  }

  getSignedUrlForTemplate(assetName, projectId): Observable<any> {
    const headers = new HttpHeaders().set("projectId", projectId);
    // let params = this.getParams(paramData);
    const url = this.baseUrl + `/assets/v1/GetTemplateSignedUrl/${assetName}`;
    return this.http.get(url, { headers: headers });
  }

  uploadTemplate(uploadUrl, postData): Observable<any> {
    const url = uploadUrl;
    return this.http.put(url, postData);
  }
  uploadPicture(uploadUrl, postData): Observable<any> {
    const headers = new HttpHeaders().set(
      "Content-Type",
      "image/png, image/jpeg; charset=utf-8"
    );
    const url = uploadUrl;
    return this.http.put(url, postData, { headers: headers });
  }

  getDownlinkParameters(versionId, paramData = {}): Observable<any> {
    const headers = new HttpHeaders().set("versionId", versionId);
    let params = this.getParams(paramData);
    const url = this.baseUrl + `/deviceprofiles/v1/GetDownlinkParameters`;
    return this.http.get(url, { params, headers: headers });
  }

  createDownlinkParameter(deviceprofileVersionId, postData, user_type): Observable<any> {
    const headers = new HttpHeaders().set(
      "deviceProfileVersionId",
      deviceprofileVersionId
    );
    const url = this.baseUrl + `/deviceprofiles/v1/CreateDownlinkParameter`;
    let forceUpdate;
    if (user_type == "Admin") {
      forceUpdate = true;
    } else {
      forceUpdate = false;
    }
    postData = {
      parameterName: postData.parameterName,
      parameterValue: postData.parameterValue,
      forceUpdate: forceUpdate
    }
    return this.http.post(url, postData, { headers: headers });
  }

  updateDownlinkParameter(
    deviceProfileVersionId,
    downlinkParameterId,
    postData, user_type
  ): Observable<any> {
    const headers = new HttpHeaders().set(
      "deviceProfileVersionId",
      deviceProfileVersionId
    );
    const url =
      this.baseUrl +
      `/deviceprofiles/v1/UpdateDownlinkParameter/` + (downlinkParameterId ? downlinkParameterId : "");
    return this.http.put(url, postData, { headers: headers });
  }

  updateDownlinkParameterForDevice(deviceId, patchData): Observable<any> {
    // const headers = new HttpHeaders().set("deviceId", deviceId);
    const url = this.baseUrl + `/devices/v1/EditDeviceParameters/${deviceId}`;
    return this.http.post(url, patchData);
  }

  deleteDownlinkParameters(options,): Observable<any> {
    // const headers = new HttpHeaders().set(
    //   "parameterIds",
    //   options["parameterIds"]
    // );
    // 

    const url = this.baseUrl + `/deviceprofiles/v1/DeleteDownlinkParameters`;
    return this.http.request("delete", url, {
      body: options,
    });
  }

  getDownlinks(versionId, paramData = {}): Observable<any> {
    const headers = new HttpHeaders().set("versionId", versionId);
    let params = this.getParams(paramData);
    const url = this.baseUrl + `/deviceprofiles/v1/GetDownlinks`;
    return this.http.get(url, { params, headers: headers });
  }

  createDownlink(deviceprofileVersionId, postData): Observable<any> {
    const headers = new HttpHeaders().set(
      // "deviceprofileVersionId",postData["deviceprofileVersionId"]
      "deviceProfileVersionId",
      deviceprofileVersionId
    );
    const url = this.baseUrl + `/deviceprofiles/v1/CreateDownlink`;
    return this.http.post(url, postData, { headers: headers });
  }

  updateDownlink(
    downlinkId,
    deviceProfileVersionId,
    patchData
  ): Observable<any> {
    const headers = new HttpHeaders().set(
      "deviceProfileVersionId",
      deviceProfileVersionId
    );
    const url =
      this.baseUrl + `/deviceprofiles/v1/UpdateDownlink/${downlinkId}`;
    return this.http.put(url, patchData, { headers: headers });
  }

  deleteDownlinks(options): Observable<any> {
    // const headers = new HttpHeaders().set("downlinkId", options["downlinkIds"]);
    // 
    const url = this.baseUrl + `/deviceprofiles/v1/DeleteDownlink`;
    return this.http.request("delete", url, { body: options });
  }

  getSignedUrlForProjectTemplate(projectId, paramData = {}): Observable<any> {
    const headers = new HttpHeaders().set("projectId", projectId.projectId);
    let params = this.getParams(paramData);
    const url = this.baseUrl + `/projects/v1/GetUploadExcelTemplateSignedUrl`;
    return this.http.get(url, { params, headers: headers });
  }

  downloadTemplateForProject(projectId, paramData = {}): Observable<any> {
    // const headers = new HttpHeaders().set("projectId", projectId);
    let params = this.getParams(paramData);
    const url =
      this.baseUrl +
      `/projects/v1/DownloadDeviceParameterExcelTemplate/${projectId}`;
    return this.http.get(url, { params });
  }
  uploadTemplateForValidation(postData): Observable<any> {
    const url =
      this.baseUrl + `/projects/v1/ProcessUploadedDeviceDownlinkExcel`;
    return this.http.post(url, postData);
  }

  downloadTemplateForAsset(projectId, paramData = {}): Observable<any> {
    let params = this.getParams(paramData);
    const headers = new HttpHeaders().set("projectId", projectId);
    const url = this.baseUrl + `/assets/v1/DownloadCreateAssetTemplate`;
    return this.http.get(url, { params, headers: headers });
  }
  getSignedUrlForAssetTemplate(projectId, paramData = {}): Observable<any> {
    const headers = new HttpHeaders().set("projectId", projectId);
    let params = this.getParams(paramData);
    const url =
      this.baseUrl + `/assets/v1/GetCreateAssetExcelTemplateSignedUrl`;
    return this.http.get(url, { params, headers: headers });
  }
  uploadTemplateForAssetValidation(postData): Observable<any> {
    const url = this.baseUrl + `/assets/v1/ProcessCreateAssetExcel`;
    return this.http.post(url, postData);
  }

  downloadTemplateForTag(projectId, paramData = {}): Observable<any> {
    const headers = new HttpHeaders().set("projectId", projectId);
    let params = this.getParams(paramData);
    const url = this.baseUrl + `/assets/v1/DownloadCreateTagsTemplate`;
    return this.http.get(url, { params, headers: headers });
  }
  getSignedUrlForTagTemplate(projectId, paramData = {}): Observable<any> {
    const headers = new HttpHeaders().set(
      // "projectId", paramData['projectId']
      "projectId",
      projectId
    );
    let params = this.getParams(paramData);
    const url = this.baseUrl + `/assets/v1/GetCreateTagsExcelTemplateSignedUrl`;
    return this.http.get(url, { params, headers: headers });
    // return this.http.get(url, { params, headers: headers });
  }
  uploadTemplateForTagValidation(postData): Observable<any> {
    const url = this.baseUrl + `/assets/v1/ProcessCreateTagsExcel`;
    return this.http.post(url, postData);
  }

  sendCommandToDass(projectId, postData): Observable<any> {
    const headers = new HttpHeaders().set("projectId", projectId);
    const url = this.baseUrl + `/devices/v1/SendCommandToDass`;
    return this.http.post(url, postData, { headers: headers });
    // return this.http.post(url, postData);
  }

  exportDevicesList(projectId): Observable<any> {
    const headers = new HttpHeaders().set("projectId", projectId);
    const url = this.baseUrl + `/devices/v1/ExportDevicesToExcel`;
    return this.http.get(url, { headers: headers });
  }

  exportDeviceProfilesList(): Observable<any> {
    // const headers = new HttpHeaders().set("createdBy", paramData["createdBy"]);
    // let params = this.getParams(paramData);
    const url = this.baseUrl + `/deviceprofiles/v1/ExportDeviceProfileToExcel`;
    return this.http.get(url);
  }

  exportUsersList(paramData = {}): Observable<any> {
    // const headers = new HttpHeaders().set("createdBy", paramData["createdBy"]);
    let params = this.getParams(paramData);
    const url = this.baseUrl + `/user/v1/ExportUserToExcel`;
    return this.http.get(url, { params });
  }

  exportAssetsList(paramData = {}): Observable<any> {
    let params = this.getParams(paramData);
    const url = this.baseUrl + `/assets/v1/ExportAssetsToExcel`;
    return this.http.get(url, { params });
  }
  getCodecModel(paramData = {}) {
    // const headers = new HttpHeaders().set(
    //   "deviceProfileVersionId",
    //   paramData["deviceProfileVersionId"]
    // );
    let params = this.getParams(paramData);
    const url = this.baseUrl + `/codec/v1/GetCodecModel`;
    return this.http.get(url, { params });
  }

  createNotifications(projectId, postData, paramData = {}): Observable<any> {
    const headers = new HttpHeaders().set("projectId", projectId);
    let params = this.getParams(paramData);
    const url = this.baseUrl + `/devices/v1/CreateNotificationRule`;
    return this.http.post(url, postData, { params, headers: headers });
  }
  getAprovedDeviceProfileNotification(projectId): Observable<any> {
    const headers = new HttpHeaders().set("projectId", projectId);
    // let params = this.getParams(paramData);
    const url = this.baseUrl + `/deviceprofiles/v1/GetApprovedDeviceProfile`;
    return this.http.get(url, { headers: headers });
  }
  getAllDevicesNotification(projectId, paramData = {}): Observable<any> {
    const headers = new HttpHeaders().set("projectId", projectId);
    let params = this.getParams(paramData);
    const url = this.baseUrl + `/devices/v1/GetAllDevices`;
    return this.http.get(url, { params, headers: headers });
  }
  getAllNotificationAlert(projectId, paramData = {}): Observable<any> {
    const headers = new HttpHeaders().set("projectId", projectId);
    let params = this.getParams(paramData);
    const url = this.baseUrl + `/projects/v1/GetNotificationAlerts`;
    return this.http.get(url, { params, headers: headers });
  }

  deleteNotificationRules(options): Observable<any> {
    // const headers = new HttpHeaders().set("ruleId", options["ruleId"]);

    const url = this.baseUrl + `/devices/v1/DeleteNotificationRules`;
    return this.http.request("delete", url, { body: options });
  }
  updateNotificationAlertsStatus(projectId, postData): Observable<any> {
    const headers = new HttpHeaders().set("projectId", projectId);
    // let params = this.getParams(paramData);
    const url = this.baseUrl + `/projects/v1/UpdateNotificationAlertsStatus`;
    return this.http.put(url, postData, { headers: headers });
  }
  

  getAllResourceCounts(projectId, paramData = {}): Observable<any> {

    const headers = new HttpHeaders().set("projectId", projectId);
    let params = this.getParams(paramData);
    const url = this.baseUrl + `/dashboard/v1/GetAllResourceCounts`;
    return this.http.get(url, { params, headers: headers });
  }
  
  createUser(postData): Observable<any> {
    // const headers = new HttpHeaders().set("createdBy", postData["parentUser"]);
    const url = this.baseUrl + `/user/v1/CreateUser`;
    return this.http.post(url, postData);
  }
 
  getDCPSTSToken(userId, token): Observable<any> {
    const url = this.baseUrl + `/user/v1/GetDCPSTSToken?userId=${userId}`;
    const headers = new HttpHeaders().set("Authorization", token.toString());
    return this.http.get(url, { headers: headers });
  }

  // var headers = new Headers();
  // headers.append(headerName, value);
  // this.http.post(url, data, {
  //   headers: headers
  // })

  // getDCPSTSToken(userId,token):Observable<any>{
  //   
  //   const url=this.baseUrl+`/user/v1/GetDCPSTSToken?userId=${userId}`

  //   let headers = new HttpHeaders();
  //   headers=headers.set('Authorization',token);
  //   

  //   return this.http.get(url,{ 'headers': headers });
  // }

  postTableau(postdata, paramData = {}): Observable<any> {
    let params = this.getParams(paramData);
    const url = "http://52.66.225.68/";
    return this.http.post(url + postdata, { params });
  }

  getProjectsList(paramData = {}): Observable<any> {
    // const headers = new HttpHeaders().set("createdBy", paramData["createdBy"]);
    let params = this.getParams(paramData);
    const url = this.baseUrl + `/projects/v1/GetProjects`;
    return this.http.get(url, { params });
  }
  
  // let method='GET';

  // let signedRequest=this.signedRequest(method,url,createdBy);
  // this.deleteSignedRequest(signedRequest);

  // 

  // this.http.get(signedRequest.url, signedRequest).subscribe(res => this.myObject = res);
  // return this.http.get(signedRequest.url, signedRequest);

  // signedRequest(method,url,createdBy){
  //   this.getUserData().subscribe((data) => {
  //     this.authUserData = data;
  //     
  //   });

  

  //   let request = {
  //     host: 'f4pfxt1jn2.execute-api.ap-south-1.amazonaws.com',
  //     method: method,
  //     url: this.baseUrl + url + `?createdBy=${createdBy}`,
  //     path: `${this.testdcp}` + url + `?createdBy=${createdBy}`
  //   }

  //   let signedRequest = aws4.sign(request, {
  //     secretAccessKey:this.authUserData.user.secretAccessKey,
  //     accessKeyId:this.authUserData.user.accessKeyId,
  //     sessionToken:this.authUserData.user.sessionToken
  //   });

  //   return signedRequest;
  // }

  // deleteSignedRequest(signedRequest){
  //   delete signedRequest.headers['Host'];
  //   delete signedRequest.headers['accessKey'];
  //   delete signedRequest.headers['secretKey'];
  //   delete signedRequest.headers['sessionToken'];
  //  }

  getRolesGroups(paramData = {}): Observable<any> {
    let params = this.getParams(paramData);
    const url = this.baseUrl + `/user/v1/GetRolesGroups`;
    return this.http.get(url, { params });
  }
  createRole(postData): Observable<any> {
    // const headers = new HttpHeaders().set("createdBy", postData["parentUser"]);
    const url = this.baseUrl + `/user/v1/CreateRole`;
    return this.http.post(url, postData);
  }
  getRoles(paramData = {}): Observable<any> {
    // const headers = new HttpHeaders().set("createdBy", paramData["createdBy"]);
    let params = this.getParams(paramData);
    const url = this.baseUrl + `/user/v1/GetRoles`;
    return this.http.get(url, { params });
  }
  getUploadLogoSignedUrl(paramData = {}): Observable<any> {
    let params = this.getParams(paramData);
    const url = this.baseUrl + `/user/v1/GetUploadLogoSignedUrl`;
    return this.http.get(url, { params });
  }
  getProjectTypes(paramData = {}): Observable<any> {
    let params = this.getParams(paramData);
    const url = this.baseUrl + `/projects/v1/GetProjectTypes`;
    return this.http.get(url, { params });
  }
  getCommunicationTechnologies(paramData = {}): Observable<any> {
    let params = this.getParams(paramData);
    const url =
      this.baseUrl + `/deviceprofiles/v1/GetCommunicationTechnologies`;
    return this.http.get(url, { params });
  }
  getProductCategories(paramData = {}): Observable<any> {
    let params = this.getParams(paramData);
    const url = this.baseUrl + `/deviceprofiles/v1/GetProductCategories`;
    return this.http.get(url, { params });
  }
  getProtocols(paramData = {}): Observable<any> {
    let params = this.getParams(paramData);
    const url = this.baseUrl + `/deviceprofiles/v1/GetProtocols`;
    return this.http.get(url, { params });
  }
  getCommandTypes(paramData = {}): Observable<any> {
    let params = this.getParams(paramData);
    const url = this.baseUrl + `/devices/v1/GetCommandTypes`;
    return this.http.get(url, { params });
  }

  getDataFormatOptions(paramData = {}): Observable<any> {
    let params = this.getParams(paramData);
    const url = this.baseUrl + `/devices/v1/GetDataFormatOptions`;
    return this.http.get(url, { params });
  }
  
  updateUser(postData, paramData = {}): Observable<any> {
    let params = this.getParams(paramData);
    const url = this.baseUrl + `/user/v1/UpdateUser`;
    return this.http.put(url, postData, { params });
  }
  
  updateRole(roleId, postData, paramData = {}): Observable<any> {
    const headers = new HttpHeaders().set("roleId", roleId);
    let params = this.getParams(paramData);
    const url = this.baseUrl + `/user/v1/UpdateRole`;
    return this.http.put(url, postData, { params, headers: headers });
  }

  getPermissions(paramData = {}, token): Observable<any> {
    let params = {};


    const headers = new HttpHeaders().set("authorization", token).set("createdBy", paramData["createdBy"]);
    const url = this.baseUrl + `/user/v1/GetPermissions`;
    // 
    // 
    // 
    return this.http.get(url, { headers: headers });
  }

  deleteLoggedInUserHistory(users): Observable<any> {


    const headers = new HttpHeaders().set("userName", users.userName);
    const url = this.baseUrl + `/user/v1/DeleteLoggedInUserHistory`;

    return this.http.request("delete", url, { body: users, headers: headers });
    // return this.http.request("delete", url, { headers: headers });
  }


  deleteLoggedInUserHistoryForNewUser(users, token): Observable<any> {


    const headers = new HttpHeaders().set("authorization", token).set("userName", users.userName);
    const url = this.baseUrl + `/user/v1/DeleteLoggedInUserHistory`;

    return this.http.request("delete", url, { body: users, headers: headers });
    // return this.http.request("delete", url, { headers: headers });
  }



  deleteRole(roleId): Observable<any> {
    const headers = new HttpHeaders().set("roleId", roleId.toString());
    const url = this.baseUrl + `/user/v1/DeleteRole`;
    return this.http.request("delete", url, { headers: headers });
  }
  
  exportRequestList(paramData = {}): Observable<any> {
    let params = this.getParams(paramData);
    const url = this.baseUrl + `/user/v1/ExportRequestToExcel`;
    return this.http.get(url, { params });
  }
  
  getUsersCount(paramData = {}): Observable<any> {
    let params = this.getParams(paramData);
    const url = this.baseUrl + `/user/v1/GetUsersCount`;
    return this.http.get(url, { params });
  }

  customerDashboardLogin(customerEmail, customerName, userType, parentUser, licence_type) {
    let user;
    Auth.currentAuthenticatedUser({
      bypassCache: true,
    })
      .then((data) => {
        user = data;
        this.getPermissions(
          { createdBy: user.attributes.email },
          user.signInUserSession.idToken.jwtToken
        )
          .subscribe(
            (data) => {
              this.permissions = data.data;
              user.signInUserSession.permission = this.permissions;
              user.signInUserSession.customerEmail = customerEmail;
              user.signInUserSession.customerName = customerName;
              user.signInUserSession.user_type = userType;
              user.signInUserSession.parentUser = parentUser
                ? parentUser
                : customerEmail;
              user.signInUserSession.licence_type = licence_type
              localStorage.setItem('signInUserSession', JSON.stringify(user.signInUserSession))
              this.store.dispatch(
                new CustomerLoggedIn({ serverResponse: user.signInUserSession })
              );
            },
            (error) => {
              if (error != "{}") {
                if (error === 504) {
                  this.toastr.error("Request time out..! Please try again.", "");
                } else if (!(error.status === 401 || error.status === 403)) {
                  this.toastr.error(
                    error.error.errorMessage
                    ? error.error.errorMessage
                    : "Something went wrong.!"
                  );
                }
              } else {
                this.toastr.error(" Please try again.", "");
              }
              // this.showLoginFormLoader = false;
            }
          );
      })
      .catch((err) => {
      });
  }

  sessionRefreshForLoggedInUser(setTimeForSessionRefreshInLocal, signOut, setShowLoginFormLoaderFn) {
    Auth.currentAuthenticatedUser({
      bypassCache: true,
    })
      .then((data) => {

        let user = data;
        this.getPermissions(
          { createdBy: user.attributes.email },
          user.signInUserSession.idToken.jwtToken
        )
          .subscribe(
            (data) => {
              let dataNew = JSON.parse(JSON.stringify(data));
              this.permissions = dataNew.data;
              user.signInUserSession.permission = Object.values(
                this.permissions
              );

              if (localStorage.getItem('isCustomerLogin') == 'true') {
                const signInUserSession = JSON.parse(localStorage.getItem('signInUserSession'));
                this.store.dispatch(
                  new CustomerLoggedIn({ serverResponse: signInUserSession })
                );
              } else {
                this.store.dispatch(
                  new LoggedIn({ serverResponse: user.signInUserSession })
                );
                setTimeForSessionRefreshInLocal();
              }
            },
            (error) => {

              signOut();
              this.toastr.error(
                error.error.errorMessage
                  ? error.error.errorMessage
                  : "Please login again."
              );
              setShowLoginFormLoaderFn(false);
            }
          );
      })
      .catch((err) => {

        this.signOutSession();
        this.toastr.error(
          err.error.errorMessage
            ? err.error.errorMessage
            : "Please login again."
        );

        signOut();
      });
  }
  userNameForAfterSession: any;

  async signOutSession() {

    try {

      this.userNameForAfterSession = decodeURIComponent(localStorage.getItem("userNameForAfterSession"));
      this.userSignOut();
      // Auth.signOut({ global: true});

      Auth.signOut();
      this._compiler.clearCache();
      sessionStorage.clear();
      localStorage.clear();
      document.location.reload();
      // this.router.navigate(['/'])
      return this.store.dispatch(new LogOut);


    } catch (error) {

    }
  }

  userSignOut() {

    let userId = this.userNameForAfterSession;

    this.sharedService.deleteLoggedInUserHistory({ userName: userId }).subscribe(data => {

    }, error => {


      this.toastr.error(error.error.errorMessage ? error.error.errorMessage : "Something went wrong.");
    })
  }





  getValidDmpUsername(dmp, userId) {
    const url = this.baseUrl + `/user/v1/ValidateDMPUsername/`   //dmpUser valid check
    return this.http.get<any>(url + "?dmpusernames=" + dmp + "&userId=" + userId)
  }

  saveDetailsBtn(postData) {
    const url = this.baseUrl + `/user/v1/SaveUserRequest/`
    return this.http.post(url, postData)
  }

  refreshDownlinkResp(deviceId): Observable<any> {
    const url = this.baseUrl + `/devices/v1/GetDownlinkRequestResponse/${deviceId}`;
    return this.http.get(url);

  }
  adminUpdateUser(postData, paramData = {}): Observable<any> {
    let params = this.getParams(paramData);
    const url = this.baseUrl + `/user/v1/AdminUpdateUser`;
    return this.http.put(url, postData, { params });
  }
  getNotificationRuleList(projectId, paramData = {}): Observable<any> {
    const url = this.baseUrl + `/devices/v1/GetNotificationRules`;
    let params = this.getParams(paramData);
    return this.http.get(url + "?projectId=" + projectId, { params });
  }
  getExportNotificationRules(projectId) {
    const headers = new HttpHeaders().set(
      "projectId",
      projectId
    );
    const url = this.baseUrl + `/devices/v1/ExportNotificationRules`;
    return this.http.get(url, { headers: headers });
  }

  getNotificationRuleForAssociate(deviceProfileID) {
    const url = this.baseUrl + `/devices/v1/GetNotificationRules`;
    return this.http.get(url + "?deviceProfileId=" + deviceProfileID);
  }
  getNotificationRuleForOnlyAssociating(deviceProfileID, deviceId) {
    const url = this.baseUrl + `/devices/v1/GetNotificationRules`;
    return this.http.get(url + "?deviceProfileId=" + deviceProfileID + "&deviceId=" + deviceId + "&flag=excludeAssociatedRules");
  }

  getAssociatedRules(deviceID) {
    const url = this.baseUrl + `/devices/v1/GetNotificationRules`;
    return this.http.get(url + "?deviceIds=" + deviceID);
  }
  dissociateRule(postdata) {
    const url = this.baseUrl + `/devices/v1/DissociateDevicesFromRules`;
    return this.http.post(url, postdata);
  }
  getAssociatedDevices(ruleId) {
    const url = this.baseUrl + `/devices/v1/GetAssociatedDevicesByRule`;
    return this.http.get(url + "?ruleId=" + ruleId);
  }

  postAssociateRules(postdata) {
    const url = this.baseUrl + `/devices/v1/AssociateDevicesToRules`;
    return this.http.put(url, postdata);
  }
  updateNotificationRule(ruleId, postdata) {
    const url = this.baseUrl + `/devices/v1/UpdateNotificationRule/${ruleId}`;
    return this.http.put(url, postdata);
  }

  getRuleDetails(ruleId) {
    const url = this.baseUrl + `/devices/v1/GetNotificationRuleDetails`;
    return this.http.get(url + "?ruleId=" + ruleId);
  }

  getUploadExcel(projectId, ruleId) {
    const headers = new HttpHeaders().set(
      "projectId",
      projectId
    );
    const url = this.baseUrl + `/devices/v1/GetUrlForRuleDeviceExcelUpload`;
    return this.http.get(url + "?ruleId=" + ruleId, { headers: headers });
  }
  getTemplate(projectId, ruleId) {
    const headers = new HttpHeaders().set(
      "projectId",
      projectId
    );
    const url = this.baseUrl + `/devices/v1/GetRuleDeviceTemplateExcel`;
    return this.http.get(url + "?ruleId=" + ruleId, { headers: headers });
  }
  uploadExcelForRule(postData) {
    const url = this.baseUrl + `/devices/v1/ProcessDeviceRuleAssociationExcel`;
    return this.http.post(url, postData);
  }

  //Get Downlink Details
  getDownlinkDetails(downlinkId): Observable<any> {
    const url = this.baseUrl + `/deviceprofiles/v1/GetDownlinkDetails/${downlinkId}`;
    return this.http.get(url);
  }
  //get downlink list
  getDownlinkResponseList(projectId, paramData = {}): Observable<any> {
    const url = this.baseUrl + `/devices/v1/GetDownlinkRequestResponse`;
    let params = this.getParams(paramData);
    return this.http.get(url + "?projectId=" + projectId, { params });
  }
  getDownlinkRequestDetails(requestId) {
    const url = this.baseUrl + `/devices/v1/GetDownlinkRequestDetails`;
    return this.http.get(url + "?requestId=" + requestId);
  }
  resetDownlinkCounter(parameterId, deviceProfileVersionId, postData, user_type) {
    const headers = new HttpHeaders().set(
      "deviceProfileVersionId",
      deviceProfileVersionId
    );

    let forceUpdate;
    if (user_type == "Admin") {
      forceUpdate = true;
    } else {
      forceUpdate = false;
    }
    postData = {
      forceUpdate: forceUpdate
    }
    const url = this.baseUrl + `/deviceprofiles/v1/ResetDownlinkCounter/${parameterId}`;
    return this.http.post(url, postData, { headers: headers });
  }
  getExportDownlink(projectId) {
    const url = this.baseUrl + `/devices/v1/ExportDownlinkRequests`;
    return this.http.get(url + "?projectId=" + projectId);
  }

  GetGroups(projectId, searchString) {
    const url = this.baseUrl + `/devices/v1/GetGroups`;
    return this.http.get(url + "?projectId=" + projectId + "&searchKey=" + searchString);
  }

  GetGroupsForMulticast(projectId, searchString, page, limit) {
    const url = this.baseUrl + `/devices/v1/GetGroups`;
    return this.http.get(url + "?projectId=" + projectId + "&searchKey=" + searchString + "&page=" + page + "&limit=" + limit);
  }

  GetGroupDetails(groupId) {
    const url = this.baseUrl + `/devices/v1/GetGroups/` + groupId;
    return this.http.get(url);
  }

  createGroup(postData, projectId): Observable<any> {
    const headers = new HttpHeaders().set("projectId", projectId);

    const url = this.baseUrl + `/devices/v1/CreateGroup`;
    return this.http.post(url, postData, { headers: headers });
  }

  UpdateGroup(postData, projectId, groupId): Observable<any> {
    const headers = new HttpHeaders().set("projectId", projectId);

    const url = this.baseUrl + `/devices/v1/UpdateGroup/` + groupId;
    return this.http.put(url, postData, { headers: headers });
  }

  getAllDevicesForGroup(projectId, paramData = {}): Observable<any> {
    const headers = new HttpHeaders().set("projectId", projectId);
    let params = this.getParams(paramData);
    const url = this.baseUrl + `/devices/v1/GetAllDevices`;
    return this.http.get(url, { params, headers: headers });
  }

  deleteGroup(projectId, groupId): Observable<any> {
    const headers = new HttpHeaders().set("projectId", projectId);
    const url = this.baseUrl + `/devices/v1/DeleteGroup/` + groupId;
    return this.http.request("delete", url, { headers: headers });
  }

  GenerateHexKey(): Observable<any> {
    const url = this.baseUrl + `/devices/v1/GenerateHexKey`;
    return this.http.get(url);
  }


  addGrouptoDevice(postData): Observable<any> {

    const url = this.baseUrl + `/devices/v1/AddToGroup`;
    return this.http.post(url, postData);
  }

  RemoveFromGroup(postData): Observable<any> {

    const url = this.baseUrl + `/devices/v1/RemoveFromGroup`;
    return this.http.post(url, postData);
  }

  getUnAssignedDevicesForGroup(projectId, paramData = {}): Observable<any> {
    const headers = new HttpHeaders().set("projectId", projectId);
    let params = this.getParams(paramData);
    const url = this.baseUrl + `/devices/v1/GetAllDevices`;
    return this.http.get(url, { params, headers: headers });
  }

  sendMulticast(postData): Observable<any> {

    const url = this.baseUrl + `/devices/v1/SendMulticast`;
    return this.http.post(url, postData);
  }
  getTemplateForGroup(projectId, groupId) {
    const headers = new HttpHeaders().set(
      "projectId",
      projectId
    );
    const url = this.baseUrl + `/devices/v1/GetGroupDeviceTemplateExcel`;
    return this.http.get(url + "?groupId=" + groupId, { headers: headers });
  }
  getUploadExcelForGroup(projectId, groupId) {
    const headers = new HttpHeaders().set(
      "projectId",
      projectId
    );
    const url = this.baseUrl + `/devices/v1/GetUrlForGroupDeviceExcelUpload`;
    return this.http.get(url + "?groupId=" + groupId, { headers: headers });
  }
  getExportGroups(projectId) {
    const headers = new HttpHeaders().set(
      "projectId",
      projectId
    );
    const url = this.baseUrl + `/devices/v1/ExportGroups`;
    return this.http.get(url, { headers: headers });
  }
  uploadExcelForRuleForGroup(postData) {
    const url = this.baseUrl + `/devices/v1/ProcessDeviceGroupAssociationExcel`;
    return this.http.post(url, postData);
  }
  getNotificationRuleForGroups(deviceProfileID, groupId) {
    const url = this.baseUrl + `/devices/v1/GetNotificationRules`;
    return this.http.get(url + "?deviceProfileId=" + deviceProfileID + "&groupId=" + groupId + "&flag=excludeAssociatedRules");
  }
  postAssociateRulesForGroups(postdata) {
    const url = this.baseUrl + `/devices/v1/AssociateGroupToRules`;
    return this.http.put(url, postdata);
  }
  getAssociatedRulesForGroups(groupId) {
    const url = this.baseUrl + `/devices/v1/GetNotificationRules`;
    return this.http.get(url + "?groupId=" + groupId);
  }
  dissociateRuleFromGroups(postdata) {
    const url = this.baseUrl + `/devices/v1/DissociateGroupFromRules`;
    return this.http.post(url, postdata);
  }
  createDashboard(postData, projectId) {
    const headers = new HttpHeaders().set(
      "projectId",
      projectId
    );
    const url = this.baseUrl + `/visualization/v1/Dashboard`;
    return this.http.post(url, postData, { headers: headers });
  }
  getDashboard(projectId) {
    const headers = new HttpHeaders().set(
      "projectId",
      projectId
    );
    const url = this.baseUrl + `/visualization/v1/Dashboard`;
    return this.http.get(url, { headers: headers });
  }
  getDashboardDetails(dashboardId) {
    const url = this.baseUrl + `/visualization/v1/Dashboard/${dashboardId}`;
    return this.http.get(url);
  }
  updateDashboard(postData, dashboardId, projectId) {
    const headers = new HttpHeaders().set(
      "projectId",
      projectId
    );
    const url = this.baseUrl + `/visualization/v1/Dashboard/${dashboardId}`;
    return this.http.put(url, postData, { headers: headers });
  }
  deleteDashboard(projectId, dashboardId) {
    const headers = new HttpHeaders().set(
      "projectId",
      projectId
    );
    const url = this.baseUrl + `/visualization/v1/Dashboard/${dashboardId}`;
    return this.http.delete(url, { headers: headers });
  }
  saveScratchWidget(postData) {
    const url = this.baseUrl + `/visualization/v1/Widget`;
    return this.http.post(url, postData);
  }
  saveAdminScratchWidget(postData) {
    const url = this.baseUrl + `/visualization/v1/admin/Widget`;
    return this.http.post(url, postData);
  }
  getWidgetDetails(widgetId) {
    const url = this.baseUrl + `/visualization/v1/Widget/${widgetId}`;
    return this.http.get(url);
  }
  getAdminWidgetDetails(widgetId) {
    const url = this.baseUrl + `/visualization/v1/admin/Widget/${widgetId}`;
    return this.http.get(url);
  }
  updateWidgets(postData, widgetId) {
    const url = this.baseUrl + `/visualization/v1/Widget/${widgetId}`;
    return this.http.put(url, postData);
  }
  updateAdminWidgets(postData, widgetId) {
    const url = this.baseUrl + `/visualization/v1/admin/Widget/${widgetId}`;
    return this.http.put(url, postData);
  }
  deleteWidget(widgetId) {
    const url = this.baseUrl + `/visualization/v1/Widget/${widgetId}`;
    return this.http.delete(url);
  }
  deleteAdminWidget(widgetId) {
    const url = this.baseUrl + `/visualization/v1/admin/Widget/${widgetId}`;
    return this.http.delete(url);
  }
  getVariablesByDataSource(dataSourceSelected, deviceProfiles, projectId): Observable<any> {
    const headers = new HttpHeaders().set(
      "projectId",
      projectId
    );
    const url = this.baseUrl + `/visualization/v1/DataSource/${dataSourceSelected}`;
    return this.http.get(url + '?deviceProfiles=' + deviceProfiles, { headers: headers });
  }
  getDataForChartAndMap(widgetId, startDate, deveuis, endDate, page, count, searchKey, assets, sortBy, sortOrder) {
    const url = this.baseUrl + `/visualization/v1/data/${widgetId}`;
    return this.http.get(url + '?from=' + startDate + "&deveuis=" + deveuis + '&to=' + endDate + "&page=" + page
      + "&count=" + count + "&search=" + searchKey + "&assets=" + assets + "&sortBy=" + sortBy + "&sortOrder=" + sortOrder);
  }
  updateSizeOfWidgets(postData) {
    const url = this.baseUrl + `/visualization/v1/Widget/UpdateDimensions`;
    return this.http.put(url, postData);
  }
  updatePositionOfWidgets(postData) {
    const url = this.baseUrl + `/visualization/v1/Widget/UpdatePositions`;
    return this.http.put(url, postData);
  }
  addFilter(postData) {
    const url = this.baseUrl + `/visualization/v1/filter`;
    return this.http.post(url, postData);
  }
  addAdminFilter(postData) {
    const url = this.baseUrl + `/visualization/v1/admin/filter`;
    return this.http.post(url, postData);
  }
  getFilterForWidget(widgetId) {
    const url = this.baseUrl + `/visualization/v1/filter`;
    return this.http.get(url + "?widgetId=" + widgetId);
  }
  getFilterForAdminWidget(widgetId) {
    const url = this.baseUrl + `/visualization/v1/admin/filter`;
    return this.http.get(url + "?widgetId=" + widgetId);
  }
  getFilterDetails(filterId) {
    const url = this.baseUrl + `/visualization/v1/filter/${filterId}`;
    return this.http.get(url);
  }
  getAdminFilterDetails(filterId) {
    const url = this.baseUrl + `/visualization/v1/admin/filter/${filterId}`;
    return this.http.get(url);
  }
  updateFilterOfWidgets(postData, filterId) {
    const url = this.baseUrl + `/visualization/v1/filter/${filterId}`;
    return this.http.put(url, postData);
  }
  updateAdminFilterOfWidgets(postData, filterId) {
    const url = this.baseUrl + `/visualization/v1/admin/filter/${filterId}`;
    return this.http.put(url, postData);
  }
  deletefilter(filterId, postData) {
    const url = this.baseUrl + `/visualization/v1/filter/${filterId}`;
    return this.http.delete(url, postData);
  }
  deleteAdminfilter(filterId, postData) {
    const url = this.baseUrl + `/visualization/v1/admin/filter/${filterId}`;
    return this.http.delete(url, postData);
  }
  getTableDataOnClick(widgetId, startDate, endDate, selectedDeveui, selectedAssetId, page, count, searchTerm, sortBy, sortOrder) {
    const url = this.baseUrl + `/visualization/v1/data/${widgetId}`;
    return this.http.get(url + "?from=" + startDate + "&to=" + endDate + "&selectedDeveui=" + selectedDeveui
      + "&selectedAssetId=" + selectedAssetId + "&page=" + page + "&count=" + count + "&search=" + searchTerm + "&sortBy=" + sortBy + "&sortOrder=" + sortOrder);
  }
  getExportWidget(widgetId, startDate, endDate, deveuis, searchKey, selectedDeveui, selectedAssetId) {
    const url = this.baseUrl + `/visualization/v1/export/${widgetId}`;
    return this.http.get(url + '?from=' + startDate + '&to=' + endDate + "&deveuis=" + deveuis
      + "&search=" + searchKey + "&selectedDeveui=" + selectedDeveui + "&selectedAssetId=" + selectedAssetId);
  }
  getAdminSideWidget(deviceProfileVersionId) {
    const url = this.baseUrl + `/visualization/v1/admin/Widget`;
    return this.http.get(url + '?deviceProfileVersionId=' + deviceProfileVersionId);
  }
  getOemWidgets(projectId, page, count, searchTerm) {
    const url = this.baseUrl + `/visualization/v1/Widget`;
    return this.http.get(url + '?projectId=' + projectId + '&status=' + 'Approved' + "&page=" + page + "&count=" + count + "&search=" + searchTerm);
  }
  saveOemWidget(postData) {
    const url = this.baseUrl + `/visualization/v1/Widget`;
    return this.http.post(url, postData);
  }
  getDefaultWidgets(projectId, page, count, searchTerm) {
    const url = this.baseUrl + `/visualization/v1/Widget`;
    return this.http.get(url + '?projectId=' + projectId + '&status=' + 'Default' + "&page=" + page + "&count=" + count + "&search=" + searchTerm);
  }
  saveDefaultWidget(postData) {
    const url = this.baseUrl + `/visualization/v1/Widget`;
    return this.http.post(url, postData);
  }
  

  onCloseOfMultiSelectDropDown(id){
    $(id).find('ng-multiselect-dropdown .item1').find('.multiselect-item-checkbox input').prop("disabled", false);
    $(id).find('ng-multiselect-dropdown .item1').find('.multiselect-item-checkbox input').css("pointer-events", "auto");
    $(id).find('ng-multiselect-dropdown .item1').find('.multiselect-item-checkbox').css("pointer-events", "auto");
  }


  initializeMultiSelectDropdown(){
    $(document).on("keyup", ".filter-textbox", function (event) {
      let lengthOfDropList=$(this).parents('.item1').siblings('.item2').find('.multiselect-item-checkbox ');
      if(lengthOfDropList.length>0){
        this.showSelectDeselectAll=true;
         $(this).parents('.item1').find('.multiselect-item-checkbox input').prop("disabled", false);
         $(this).parents('.item1').find('.multiselect-item-checkbox input').css("pointer-events", "auto");
         $(this).parents('.item1').find('.multiselect-item-checkbox').css("pointer-events", "auto");
      }else{
        $(this).parents('.item1').find('.multiselect-item-checkbox input').prop("disabled", true);
        $(this).parents('.item1').find('.multiselect-item-checkbox input').css("pointer-events", "none");
        $(this).parents('.item1').find('.multiselect-item-checkbox').css("pointer-events", "none");
        this.showSelectDeselectAll=false;
      }
      });
  }


  unBindMultiSelect() {
    $(document).off("keyup", ".filter-textbox");
  }

}
