import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {TranslationService} from './modules/i18n';

import { Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { Auth } from "aws-amplify";
import { ToastrService } from "ngx-toastr";
import { SharedServiceService } from "./services/shared-service/shared-service.service";
import { LoggedIn, LogInSuccess } from "./store/actions/auth.actions";
import { AuthState } from "./store/app.states";


@Component({
  // tslint:disable-next-line:component-selector
  selector: 'body[root]',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
  // changeDetection: ChangeDetectionStrategy.Default,
})
export class AppComponent implements OnInit {

  title = "tcl-iot";
  showLoginFormLoader = false;
  permissions = [];
  intervalID;
  // user;
  userAuthenicated;

  constructor( private store: Store<AuthState>,
    private sharedService: SharedServiceService,
    private toastr: ToastrService,
    private router: Router) {
    // register translations
    this.sharedService.getUserData().subscribe((data) => {
      this.userAuthenicated = data;
    });
    // setInterval(() => {
    //   this.sessionRefresh();
    //   window.clearTimeout(1000);
    // }, 300000);
    // this.sessionRefresh();
    // this.checkForRefreshSession();
  }

  ngOnInit() {

    if (!this.userAuthenicated.isAuthenticated) {
      this.signOut();
      // this.router.navigate(['/', 'admin', 'dashboard']);
      // window.location.reload();
    }
    setInterval(() => {
      this.checkForRefreshSession();
    }, 1000);
    // else {
    //   this.signOut();
    // }
    // this.intervalID = setInterval(() => {
    //   // Auth.currentSession()
    //   Auth.currentAuthenticatedUser({
    //     bypassCache: true
    //   }).then(data => {
    //     
    //     this.user = data;
    //     // 
    //     
    //     this.sharedService.getPermissions({ createdBy: this.user.attributes.email }, this.user.signInUserSession.idToken.jwtToken).subscribe(data => {
    //       this.permissions = data.data
    //       this.user.signInUserSession.permission = this.permissions
    //       this.store.dispatch(
    //         new LoggedIn({ serverResponse: this.user.signInUserSession })
    //       );
    //     }, error => {
    //       
    //       this.signOut()
    //       this.showLoginFormLoader = false;
    //     })
    //   })
    //     .catch(err => {
    //       
    //       this.signOut();
    //     });
    // }, 23 * 60 * 60 * 1000);
    // 24 * 60 * 60 * 1000
    // 50 * 60 * 1000
  }

  checkForRefreshSession() {
    
    // const date1 = new Date();
    // const date2 = new Date(JSON.parse(window.localStorage.refreshedOn));
    // const diffTime = date1.getMinutes() - date2.getMinutes();
    // if (diffTime >= 5) {
    //   window.clearTimeout(1000);
      //go for session refresh
      //set timeout again
      // this.sessionRefresh();
    //}
  }

  async sessionRefresh() {
    // this.intervalID = setInterval(() => {
    // Auth.currentSession()
    
    this.sharedService.sessionRefreshForLoggedInUser(this.setTimeForSessionRefreshInLocal, this.signOut, this.setShowLoginFormLoaderFn);
    // Auth.currentAuthenticatedUser({
    //   bypassCache: true,
    // })
    //   .then((data) => {
        
    //     let user = data;
    //     this.sharedService
    //       .getPermissions(
    //         { createdBy: user.attributes.email },
    //         user.signInUserSession.idToken.jwtToken
    //       )
    //       .subscribe(
    //         (data) => {
    //           console.log('<><>>< from app component');
    //           let dataNew = JSON.parse(JSON.stringify(data));
    //           this.permissions = dataNew.data;
    //           user.signInUserSession.permission = Object.values(
    //             this.permissions
    //           );
    //           this.store.dispatch(
    //             new LoggedIn({ serverResponse: user.signInUserSession })
    //           );
    //           this.setTimeForSessionRefreshInLocal();
    //         },
    //         (error) => {
    //           this.signOut();
    //           this.toastr.error(
    //             error.error.errorMessage
    //               ? error.error.errorMessage
    //               : "Please login again."
    //           );
    //           this.showLoginFormLoader = false;
    //         }
    //       );
    //   })
    //   .catch((err) => {
    //     this.toastr.error(
    //       err.error.errorMessage
    //         ? err.error.errorMessage
    //         : "Please login again."
    //     );
    //     this.signOut();
    //   });
  }

  // , 23 * 60 * 60 * 1000);
  // 24 * 60 * 60 * 1000
  // 50 * 60 * 1000
  // }

  setShowLoginFormLoaderFn(val) {
    this.showLoginFormLoader = val;
  }

  async signOut() {
    try {
      // await Auth.signOut();
      // sessionStorage.clear();
      // localStorage.clear();
      // window.clearTimeout(1000);
    } catch (error) {
      console.log('error in appcomp when signout');
    }
  }
  setTimeForSessionRefreshInLocal() {
    var date = new Date();
    window.localStorage.setItem("refreshedOn", JSON.stringify(date));
  }
}
