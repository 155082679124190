import { NgModule, APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule, NoopAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { HttpClientInMemoryWebApiModule } from 'angular-in-memory-web-api';
import { ClipboardModule } from 'ngx-clipboard';
import { TranslateModule } from '@ngx-translate/core';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthService } from './modules/auth/services/auth.service';
import { environment } from 'src/environments/environment';

import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { ActionReducer, MetaReducer, StoreModule } from "@ngrx/store";
import { reducers } from "./store/app.states";
import { localStorageSync } from "ngrx-store-localstorage";
import { AccountsModule } from "./modules/core-modules/accounts.module";
import { EffectsModule } from "@ngrx/effects";
import { AuthEffects } from "./store/effects/auth.effects";
import { ToastrModule } from "ngx-toastr";
import { NgxPaginationModule } from "ngx-pagination";

//import { CodemirrorModule } from '@ctrl/ngx-codemirror';

//Angular Material
import {MatIconModule} from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';

import * as bootstrap from 'bootstrap';

import { Ng2OrderModule } from 'ng2-order-pipe';
import { SharedModule } from "./modules/shared/shared.module";
import { NgxSpinnerModule } from "ngx-spinner";
const modules = [
 
  MatIconModule,
  MatButtonModule
];

export function localStorageSyncReducer(
  reducer: ActionReducer<any>
): ActionReducer<any> {
  return localStorageSync({
    keys: ["auth"],
    rehydrate: true,
    checkStorageAvailability: true,
  })(reducer);
}

const metaReducers: Array<MetaReducer<any, any>> = [localStorageSyncReducer];

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    TranslateModule.forRoot(),
    HttpClientModule,
    ClipboardModule,
    AppRoutingModule,
    InlineSVGModule.forRoot(),
    NgbModule,
    NoopAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    StoreModule.forRoot(reducers, { metaReducers }),
    EffectsModule.forRoot([AuthEffects]),
    AccountsModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot({
      timeOut: 3000,
      positionClass: "toast-top-right",
      preventDuplicates: true,
      closeButton: true,
      progressBar: true,
      progressAnimation: "decreasing",
    }),
    MatIconModule,
    NgxPaginationModule,
    Ng2OrderModule,
    SharedModule,
    NgxSpinnerModule
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA,
    NO_ERRORS_SCHEMA
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
