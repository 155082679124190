import * as auth from './reducers/auth.reducers';
import { createFeatureSelector } from '@ngrx/store';

export interface AuthState {
    authState: auth.State;
}

export const reducers = {
    auth: auth.reducer,
};

export const authState = createFeatureSelector<AuthState>('auth');