import { User } from '../../models/user';
import { AuthActionTypes, All } from '../actions/auth.actions';
import { SharedServiceService } from '../../services/shared-service/shared-service.service'
export interface State {
    // is a user authenticated?
    isAuthenticated: boolean;
    // if authenticated, there should be a user object
    user: User | null;
    // Server Response
}

export const initialState: State = {
    isAuthenticated: false,
    user: null
};


function procesUserData(userData) {
    
    let user = null;
    let permissions = [];
    if (userData.idToken) {
        // permissions = JSON.parse(sessionStorage.getItem("permission"))
        
        user = {
            name: userData.idToken.payload.name,
            email: userData.idToken.payload.email,
            customerEmail: userData.customerEmail ? userData.customerEmail : "",
            customerName: userData.customerName ? userData.customerName : "",
            user_type:userData.idToken.payload['custom:user_type'],
            token: userData.idToken.jwtToken,
            refreshToken: userData.refreshToken.token,
            dmp_usernames: userData.idToken.payload['custom:dmp_usernames'],
            parentUser: userData.idToken.payload['custom:parent_id'] ? userData.idToken.payload['custom:parent_id'] : "",
            accessKeyId: userData.accessKeyId,
            secretAccessKey: userData.secretAccessKey,
            sessionToken: userData.sessionToken,
            permission: userData.permission,
            licence_type: userData.idToken.payload['custom:licence_type'] ? userData.idToken.payload['custom:licence_type'] : "ExplorerCanPublish"
        }
    }
    return { "user": user };
}
function processDashboardUserData(userData) {
    console.log(userData)
    let user = null;
    let permissions = [];
    if (userData.idToken) {
        // permissions = JSON.parse(sessionStorage.getItem("permission"))
        
        user = {
            name: userData.idToken.payload.name,
            email: userData.idToken.payload.email,
            customerEmail: userData.customerEmail ? userData.customerEmail : "",
            customerName: userData.customerName ? userData.customerName : "",
            user_type:userData.user_type ? userData.user_type: userData.idToken.payload['custom:user_type'],
            token: userData.idToken.jwtToken,
            refreshToken: userData.refreshToken.token,
            dmp_usernames: userData.idToken.payload['custom:dmp_usernames'],
            parentUser:userData.parentUser ? userData.parentUser : userData.idToken.payload['custom:parent_id'] ? userData.idToken.payload['custom:parent_id'] : "",
            accessKeyId: userData.accessKeyId,
            secretAccessKey: userData.secretAccessKey,
            sessionToken: userData.sessionToken,
            permission: userData.permission,
            licence_type: userData['licence_type'] ? userData['licence_type'] : "ExplorerCanPublish"
        }
    }
    return { "user": user };
}


export function reducer(state = initialState, action: All): State {
    
    //
   
    switch (action.type) {
        case AuthActionTypes.LOGIN_SUCCESS: {
            return {
                ...state,
                isAuthenticated: true,
                user: procesUserData(action.payload.serverResponse)["user"],
            };
        }
        case AuthActionTypes.LOGGED_IN: {
            return {
                ...state,
                isAuthenticated: true,
                user: procesUserData(action.payload.serverResponse)["user"],
            };
        }
        case AuthActionTypes.LOGIN_FAILURE: {
            return {
                ...state,
            };
        }
        case AuthActionTypes.LOGOUT: {
            return initialState;
        }
        case AuthActionTypes.CUSTOMER_LOGGED_IN: {
            return {
                ...state,
                isAuthenticated: true,
                user: processDashboardUserData(action.payload.serverResponse)["user"],
            };
        }
        default: {
            return state;
        }
    }
}