import { Injectable, Injector } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpParams
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { SharedServiceService } from '../../shared-service/shared-service.service';
import { environment } from 'src/environments/environment';
var aws4 = require('aws4');

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  private sharedService: SharedServiceService;
  baseUrl = environment.baseUrl;
  authUserData;
  testdcp = '/testdcp';
  token = '';
  refreshToken;
  constructor(private injector: Injector) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
   
    this.sharedService = this.injector.get(SharedServiceService);
    this.sharedService.getUserData().subscribe((data) => {
      this.authUserData = data;
      
    });
    this.sharedService.getToken().subscribe((data) => {
      this.token = data;
      //this.token='abcd';
      if (this.token) {
        
        if (request.urlWithParams.indexOf('X-Amz-Credential') > -1 ||
          request.url.indexOf(this.baseUrl + `/user/v1/GetPermissions`) > -1) {
          request = request.clone({
            setHeaders: {
              // 'Authorization': `token ${token}`,
              // 'Content-Type': 'application/json'
            }
          });
        } else {
          var newRequest = {}
          let methodType = request.method
          
          
          
          // // created by
          // if (request.params.get("createdBy") == null) {
          //   if (methodType.toLocaleLowerCase() == "get" || methodType.toLocaleLowerCase() == "delete") {
          //     request = request.clone({
          //       params: request.params.append("createdBy", this.authUserData.user.customerEmail ? this.authUserData.user.customerEmail : this.authUserData.user.email)
          //     })
          //   } else {
          //     const body = {
          //       ...request.body as {}, "createdBy": this.authUserData.user.customerEmail ? this.authUserData.user.customerEmail : this.authUserData.user.email
          //     }
          //     request = request.clone({
          //       body
          //     })
          //   }
          // }

          // // parent user id
          // if (methodType.toLowerCase() == "get" || methodType.toLowerCase() == "delete") {
          //   request = request.clone({
          //     params: request.params.append("parentUserId", this.authUserData.user.parentUser ? this.authUserData.user.parentUser : this.authUserData.user.email)
          //   })
          // } else {
          //   const body = {
          //     ...request.body as {}, "parentUserId": this.authUserData.user.parentUser ? this.authUserData.user.parentUser : this.authUserData.user.email
          //   }
          //   request = request.clone({
          //     body
          //   })
          // }

          request = request.clone({
            setHeaders: {
           'Authorization': `${this.token}`,
           //'Authorization': 'abcd',
              "createdBy": this.authUserData.user.customerEmail ? this.authUserData.user.customerEmail : this.authUserData.user.email,
              "parentUserId":this.authUserData.user.parentUser ? this.authUserData.user.parentUser : this.authUserData.user.email,
              // "createdBy": this.authUserData?.user?.email
              // 'Content-Type': 'application/json'
            }
          });
        }
      }
    }, error => {
      
    });
    return next.handle(request);
  }
}











//     this.sharedService.getUserData().subscribe((data) => {
//       this.authUserData = data;
//       
//       this.sharedService.getToken().subscribe((data) => {

//         let token = data;
//         if (token) {
//           
//           let signedRequest=this.signedRequest(request)
//           let authData;
//             if (localStorage.getItem("auth")) {
//               authData = JSON.parse(localStorage.getItem("auth"));
//              
//             }
//           if (request.urlWithParams.indexOf('X-Amz-Credential') > -1 ||
//           request.url.indexOf(this.baseUrl+`/user/v1/GetDCPSTSToken?userId=`) > -1 ) {
//             
//             // request = request.clone({
//               // setHeaders: {
//               // 'Authorization': `token ${token}`,
//               // 'Content-Type': 'application/json'
//               // }
//             // });

//           } else if(authData.user && authData.user.accessKeyId != ''){
//             


//             if (authData.user && authData.user.accessKeyId != '') {
//               
//               

//               request = request.clone({
//                 setHeaders: {
//                   // "accessKey": authData.user.accessKeyId,
//                   // "secretKey": authData.user.secretAccessKey,
//                   // "sessionToken": authData.user.sessionToken,
//                   // "service":'execute-api',
//                   // "region":'ap-south-1'
//                   // sessionToken:authUserData.user.sessionToken,
//                   // "Authorization": `token ${token}`,
//                   // 'Content-Type': 'application/json'
//                   // signedRequest
//                   'Authorization':signedRequest.headers.Authorization,
//                   'X-Amz-Date':signedRequest.headers['X-Amz-Date'],
//                   'X-Amz-Security-Token':signedRequest.headers['X-Amz-Security-Token'],
//                   // 'Host':signedRequest.headers.host,
//                   // 'method':signedRequest.method,
//                   // 'url':signedRequest.url,
//                   // 'path':signedRequest.path


//                 }
//               });
//             } else {
//               request = request.clone({
//                 setHeaders: {
//                   'Authorization': `token ${token}`,
//                   // 'Content-Type': 'application/json'
//                 }
//               });
//             }
//           }
//         }
//       });
//     });



//     return next.handle(request);
//   }

// // 

// // let signedRequest=this.signedRequest(method,url,createdBy);
// // 
// // this.deleteSignedRequest(signedRequest);


// signedRequest(request){
//   // 
//   let requestPath=request.urlWithParams
//   var newRequest={}
//   if(request.method=='GET'){
//     newRequest = {
//       host: 'f4pfxt1jn2.execute-api.ap-south-1.amazonaws.com',
//       method: request.method,
//       url: request.urlWithParams,
//       path: requestPath.split('https://f4pfxt1jn2.execute-api.ap-south-1.amazonaws.com')[1]
//       }
//   }else{
//     newRequest = {
//       headers:{
//         'Content-Type': 'application/json'
//       },
//       body: request.body,
//       host: 'f4pfxt1jn2.execute-api.ap-south-1.amazonaws.com',
//       method: request.method,
//       url: request.urlWithParams,
//       path: requestPath.split('https://f4pfxt1jn2.execute-api.ap-south-1.amazonaws.com')[1]
//       }
//   }

//     // 
//   let signedRequest = aws4.sign(newRequest, {
//     secretAccessKey:this.authUserData.user.secretAccessKey,
//     accessKeyId:this.authUserData.user.accessKeyId,
//     sessionToken:this.authUserData.user.sessionToken
//   });

//   // 
// this.deleteSignedRequest(signedRequest);
// return signedRequest;
// }


// deleteSignedRequest(signedRequest){
// delete signedRequest.headers['Host'];
// delete signedRequest.headers['accessKey'];
// delete signedRequest.headers['secretKey'];
// delete signedRequest.headers['sessionToken'];
// }
// }